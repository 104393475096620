import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { organization_user_state } from './type'
import { getPageList, editPageData } from "@/service/modules/systems";

const organization_userModule: Module < organization_user_state, IRootState >= {
    namespaced: true,
    state() {
        return {
            organization_user_List: [],
            organization_user_Count: 0, //数据条数
            organization_Info: []
        }
    },
    mutations: {
        change_organization_user_List(state, organization_user_List: any[]) {
            state.organization_user_List = organization_user_List
        },
        change_organization_user_Count(state, organization_user_Count: number) {
            state.organization_user_Count = organization_user_Count
        },
        change_organization(state,payload:any){
          state.organization_Info = payload
        }
    },
    getters: {
        pageListData(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_List`] //选择对应数据
            }
        },
        pageListCount(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_Count`] //选择对应数据条数
            }
        }
    },
    actions: {
        async getPageListAction({ commit }, payload: any) {
            // // 1.获取pageUrl
            // // 1.获取pageUrl
            // const pageName = payload.pageName||''
            const pageUrl = `/custom/get/organization_user`
            // // 2.对页面发送请求
            const pageResult = await getPageList(pageUrl, payload.queryInfo)
                // console.log(pageResult);
                // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
                // // 3.将数据存储到state中
            const { data, totalRecord } = pageResult.data
            console.log(data);
            commit('change_organization_user_List', data)
            commit('change_organization_user_Count', totalRecord)
        },
        async getPageTreeListActrion({commit},payload:any)
        {
          const pageUrl = `/custom/get/operation_userdetail`
          // // 2.对页面发送请求
          if(!payload){
            payload={
              queryInfo:{}
            }
          }
          const pageResult = await getPageList(pageUrl, payload.queryInfo)
              console.log(pageResult,'看看请求结果');
              // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
              // // 3.将数据存储到state中
          const { data, totalRecord } = pageResult.data
          console.log(data);
          data.forEach((v:any) => {
              if(!v.children)
              {
                v.children = []
              }else{
                v.children = JSON.parse(v.children)
              }

          });
          commit('change_organization', data)
        },
        async createPageTreeListAction({ dispatch }, payload: any) {
          // 1.创建数据的请求
          const { pageName, newData } = payload
          const pageUrl = `/custom/add/operation_userdetail`

          console.log('看看添加的用户', payload)
          await editPageData(pageUrl, payload)
              // await editPageData(pageUrl,payload)

          // 2.请求最新的数据
          dispatch('getPageTreeListActrion', {
              queryInfo: {}
          })
      },
      async updatePageTreeListAction({ dispatch }, payload: any){
        const { pageName, newData } = payload
        const pageUrl = `/custom/update/operation_userdetail`

        console.log('看看修改的内容', payload)
        await editPageData(pageUrl, payload)
            // await editPageData(pageUrl,payload)

        // // 2.请求最新的数据
        // dispatch('getPageTreeListActrion', {
        //     queryInfo: {}
        // })
      },
        async createPageDataAction({ dispatch }, payload: any) {
            // 1.创建数据的请求
            const { pageName, newData } = payload
            const pageUrl = `/custom/add/organization_user`

            console.log('看看添加的用户', payload)
            await editPageData(pageUrl, newData)
                // await editPageData(pageUrl,payload)

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },

        async editPageDataAction({ dispatch }, payload: any) {
            // 1.编辑数据的请求
            const { pageName, editData, id } = payload
            console.log(editData)
            const pageUrl = `/custom/update/organization_user`
            await editPageData(pageUrl, {...editData, id })

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                pageName,
                queryInfo: {}
            })
        },
        async deletePageDataAction({ dispatch }, payload: any) {
            // 1.获取pageName和id
            // pageName -> /users
            // id -> /users/id
            const { deleteData } = payload
            console.log('我点击了解绑', deleteData)
            const pageUrl = `/custom/del/organization_user`
            let dataInfo = {
                id: deleteData.option
            }
            await editPageData(pageUrl, dataInfo)
                // 2.调用删除网络请求
                // await deletePageData(pageUrl)
                // 3.重新请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },

        async deletePageDataDetailAction({ dispatch }, payload: any) {
          // 1.获取pageName和id
          // pageName -> /users
          // id -> /users/id
          const { deleteData } = payload
          console.log('我点击了解绑', deleteData)
          const pageUrl = `/custom/del/operation_userdetail`
          let dataInfo = {
              id: deleteData.option
          }
          await editPageData(pageUrl, dataInfo)
              // 2.调用删除网络请求
              // await deletePageData(pageUrl)
              // 3.重新请求最新的数据
          dispatch('getPageTreeListActrion', {
              queryInfo: {}
          })
      },
    }
}

export default organization_userModule
