const smart_ai = ()=>import('@/views/firesecurity/smart_ai/index.vue')
export default {
  path: '/system/smart_ai',
  name: 'smart_ai',
  component: smart_ai,
  meta:{
    pid:10,
    id:4,
    index:1,
  },
  children: []
}
