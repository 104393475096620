
import YCX_Request from "./request";
import { TIME_OUT,BASE_URL } from "./request/config";
import localCache from '@/utils/cache'
import { ElMessage } from 'element-plus'
import router from '@/router'

const ycx_Request = new YCX_Request({
  baseURL:BASE_URL,
  timeout:TIME_OUT,
  interceptors:{
    requestInterceptor:(config:any)=>{
      // console.log('我是当前实例的请求拦截');
      const token = localCache.getCache('token')
      if (token) {
        config.headers.Authorization = `${token}`
      }
      return config
    },

    responseInterceptor:(config)=>{
      let data = config.data
      if(data.code!==200&&data.code!==415){
        console.log(data)
        ElMessage({
          message:data.message,
          type: 'warning',
        })
      }else if(data.code==415)
      {
        ElMessage({
          message:data.message,
          type: 'warning',
        })

        setTimeout(() => {
          router.push({
            path: '/login'
          })
        }, 2000);
      }
      else{
        // ElMessage({
        //   message:data.message,
        //   type: 'success',
        // })
      }
      // console.log('我是当前实例的响应拦截');
      return config.data
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptorCatch: (err) => {
      return err
    }
  }
})
export default ycx_Request
