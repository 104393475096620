const emergency_conduct = ()=>import('@/views/firesecurity/emergency_conduct/index.vue')
export default {
  path: '/system/emergency_conduct',
  name: 'emergency_conduct',
  component: emergency_conduct,
  meta:{
    pid:5,
    id:1,
    index:0,
  },
  children: []
}
