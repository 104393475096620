const facility_report = ()=>import('@/views/firesecurity/facility_report/index.vue')
export default {
  path: '/system/facility_report',
  name: 'facility_report',
  component: facility_report,
  meta:{
    pid:2,
    id:1,
    index:0,
  },
  children: []
}
