import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { order_table_state } from './type'
import { getPageList,editPageData } from "@/service/modules/systems";

const order_tableModule:Module<order_table_state,IRootState>={
    namespaced:true,
    state(){
        return{
            order_table_List: [
                // {
                //     name:'测试',
                //     realname:'测试信息',
                //     cellphone:16666666666,
                //     enable:1,
                // }
            ],
            order_table_Count: 0,//数据条数

        }
    },
    mutations:{
        change_order_table_List(state, order_table_List: any[]) {
            state.order_table_List = order_table_List
          },
          change_order_table_Count(state, order_table_Count: number) {
            state.order_table_Count = order_table_Count
          },
    },
    getters:{
        pageListData(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_List`]//选择对应数据
            }
          },
          pageListCount(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_Count`]//选择对应数据条数
            }
          }
    },
    actions:{
      async getPageListAction({ commit }, payload: any) {
         // // 1.获取pageUrl
        // // 1.获取pageUrl
        const pageName = payload.pageName
        const pageUrl = `/admin/get/getorder_table/orderTable`

        // // 2.对页面发送请求
        const pageResult = await getPageList(pageUrl, payload.queryInfo)
        // console.log(pageResult);
        // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构

        // // 3.将数据存储到state中
        const { data, totalRecord } = pageResult.data
        console.log(data,'看看是否有加载数据');
        commit('change_order_table_List',data)
        commit('change_order_table_Count',totalRecord)
      },
      async createPageDataAction({ dispatch }, payload: any) {
        // 1.创建数据的请求
        const { pageName, newData } = payload
        const pageUrl = `/${pageName}`
        // await createPageData(pageUrl, newData)
        await editPageData(pageUrl, newData)

        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {}
        })
      },

      async editPageDataAction({ dispatch }, payload: any) {
        // 1.编辑数据的请求
        const { pageName, editData, id } = payload
        console.log(editData)
        const pageUrl = `/${pageName}/${id}`
        await editPageData(pageUrl, editData)

        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {}
        })
      },
      async deletePageDataAction({ dispatch }, payload: any) {
        // 1.获取pageName和id
        // pageName -> /users
        // id -> /users/id
        const { pageName, id } = payload
        const pageUrl = `/${pageName}/${id}`
        await editPageData(pageUrl, {id})
        // 2.调用删除网络请求
        // await deletePageData(pageUrl)

        // 3.重新请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: { }
        })
      },
    }
}

export default order_tableModule
