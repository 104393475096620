const analysis = ()=>import('@/views/firesecurity/analysis/index.vue')
export default {
  path: '/system/analysis',
  name: 'analysis',
  component: analysis,
  meta:{
    pid:6,
    id:3,
    index:0,
  },
  children: []
}
//大数据页面
