const notice = ()=>import('@/views/firesecurity/notice/index.vue')
export default {
  path: '/system/notice',
  name: 'notice',
  component: notice,
  meta:{
    pid:6,
    id:1,
    index:0,
  },
  children: []
}
