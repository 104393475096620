import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ycx_Request from './service'
import { setupStore } from './store'
import { registerApp } from './global' //按需引入Element-plus
import '@/assets/icon_font/iconfont.css'
import '@/assets/css/global.css'
import VueSocketIO from 'vue-socket.io'
import moment from 'moment'

import { registerSockets, destroySockets } from './utils/Socket/socket'
let app = createApp(App)
app.use(registerApp) //安装插件的写法
let wb_socket =  new VueSocketIO({
  debug: false, // debug调试，生产建议关闭
  // connection: SocketIO('ws://127.0.0.1:7006/', options),
  // connection: 'ws://219.152.51.202:7013/',
  connection: 'ws://192.168.59.101:7013/',
  // query: {
  //   room: 'demo',
  //   userId: `client_${Math.random()}`
  // },
  options: { autoConnect: false },
  extraHeaders: {
    'Access-Control-Allow-Origin': '*', // 设置跨域请求头
  },
})
wb_socket.io.connect()
app.config.globalProperties.$moment = moment
app.config.globalProperties.$addSockets = registerSockets
app.config.globalProperties.$removeSockets = destroySockets
app.config.globalProperties.$socket = wb_socket
// app.use(wb_socket)
app.use(store)
setupStore()
app.use(router)
app.mount('#app')

// ycx_Request.request({
//   url:'/',
//   method:'GET'
// })
// console.log(process.env.VUE_APP_BASE_URL,'http');

// createApp(App).mount('#app')
