const datacenter = ()=>import('@/views/firesecurity/datacenter/index.vue')
export default {
  path: '/system/datacenter',
  name: 'datacenter',
  component: datacenter,
  meta:{
    pid:1,
    id:1,
    index:0,
  },
  children: []
}
//大数据页面
