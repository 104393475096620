const polling_task_month = ()=>import('@/views/firesecurity/polling_task_month/index.vue')
export default {
  path: '/system/polling_task_month',
  name: 'polling_task_month',
  component: polling_task_month,
  meta:{
    pid:3,
    id:2,
    index:0,
  },
  children: []
}
