import { Module } from "vuex";
import { ILoginState } from "./types";
import { IRootState } from "../types";
import { mapMenusToRoutes,mapMenusToPermissions } from '@/utils/map-menus';
import router from '@/router'
import { menus } from "../menus/modules";
import localCache from '@/utils/cache'
import { Base64 } from "js-base64";
import {
  accountLoginRequest,
  requestUserInfoById,
  requestUserMenusByRoleId
} from '@/service/login/login'
import { getPageList, editPageData } from "@/service/modules/systems";

import { menusList,sortKey } from "@/utils/list-menus";
const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: "",
      userInfo: {},
      userMenus: [],
      company:"",
      homeInfo:{},
      analysisInfo:{},
      first_menus:[
        // {
        //   name: '大数据页面',
        //   url: '/analysis/datedetail',
        //   id: 1,
        //   children: [
        //     {
        //       name: '大数据页面',
        //       url: '/analysis/datedetail',
        //       value:"analysis"
        //     }
        //   ]
        // },

        {
          name: '数据中心',
          id: 80,
          isHidden:false,
          url: '/system/datacenter',
          children: []
        },
         {
          name: '监控系统',
          id: 1111,
          isHidden:false,
          url: '/system/facility_report',
          children: [
            {
              name: '设备报警',
              url: '/system/facility_report',
              value:"dateInfo_checkfaility"
            },
            {
              name: '故障报警',
              url: '/system/error_report',
              value:"schedule_userplan"
            },
          ]
        },
        {
          name: '视频会议',
          id: 2222,
          isHidden:false,
          url: '/system/facility_report',
          children: [
            {
              name: '设备报警',
              url: '/system/facility_report',
              value:"dateInfo_checkfaility"
            },
            {
              name: '故障报警',
              url: '/system/error_report',
              value:"schedule_userplan"
            },
          ]
        },

        {
          name: '设备报警',
          id: 7,
          isHidden:false,
          url: '/system/facility_report',
          children: [
            {
              name: '设备报警',
              url: '/system/facility_report',
              value:"dateInfo_checkfaility"
            },
            // {
            //   name: '故障报警',
            //   url: '/system/error_report',
            //   value:"schedule_userplan"
            // },
          ]
        },
        {
          name: '巡检巡查',
          isHidden:false,
          url: '/system/polling_task_time',
          id: 21,
          children: [
            {
              name: '巡检任务',
              url: '/system/polling_task_time',
              value:"dateInfo_faility"
            },
            // {
            //   name: '月检任务',
            //   url: '/system/polling_task_month',
            //   value:"dateInfo_report"
            // },
            // {
            //   name: '年检任务',
            //   url: '/system/polling_task_year',
            //   value:"dateInfo_report"
            // },
            // {
            //   name: '巡检处理',
            //   url: '/system/polling_record',
            //   value:"dateInfo_report"
            // },
          ]
        },
        {
          name: '设施报修',
          isHidden:false,
          url: '/system/error_fixed',
          id: 4,
          children: [
            {
              name: '设施报修',
              url: '/system/error_fixed',
              value:"dateInfo_faility"
            },
          ]
        },
        {
          name: '应急指挥',
          isHidden:false,
          url: '/system/emergency_conduct',
          id: 2,
          children: [
            {
              name: '应急指挥',
              url: '/system/emergency_conduct',
              value:"dateInfo_faility"
            },
            {
              name: '应急预案',
              url: '/system/emergency_scheme',
              value:"dateInfo_faility"
            },
          ]
        },
        {
          name:"通知公告",
          id:16,
          isHidden:false,
          url:"/system/notice",
          children:[
            {
              name:"通知公告",
              url:"/system/notice",
            }
          ]
        },
        {
          name:"风险评估",
          id:23,
          isHidden:true,
          url:"/system/risk_assessment",
          children:[
            {
              name:"机构评分",
              url:"/system/risk_assessment",
            },
            {
              name:"人员评分",
              url:"/system/performance_assessment",
            },
            // {
            //   name:"AI报告",
            //   url:"/system/ai_report",
            // },
            {
              name:"考评设置",
              url:"/system/risk_performance",
            },
          ]
        },
        {
          name:"机构管理",
          id:8,
          isHidden:true,
          url:"/system/institutional_information",
          children:[
            {
              name:"机构管理",
              url:"/system/institutional_information",
            }
          ]
        },
        {
          name:"人员管理",
          id:12,
          isHidden:true,
          url:"/system/user_info",
          children:[
            {
              name:"机构人员",
              url:"/system/user_info",
            },
            {
              name:"养老人员",
              url:"/system/user_info_older",
            },
          ]
        },
        {
          name:"智慧设备",
          id:6,
          isHidden:true,
          url:"/system/facility_manager",
          children:[
            {
              name:"传统消防",
              url:"/system/facility_manager",
            },
            {
              name:"智慧用电",
              url:"/system/smart_electro",
            },
            {
              name:"水位水压",
              url:"/system/water_gage",
            },
            {
              name:"智慧AI",
              url:"/system/smart_ai",
            }
          ]
        },
        {
          name:"传统设施",
          id:29,
          isHidden:true,
          url:"/system/facilities_manager",
          children:[
            {
              name:"设施管理",
              url:"/system/facilities_manager",
            }
          ]
        },
        {
          name:"运维管理",
          id:13,
          isHidden:true,
          url:"/system/maintain_manager",
          children:[
            {
              name:"运维管理",
              url:"/system/maintain_manager",
            },
            {
              name:"运维设置",
              url:"/system/maintain_voice_record",
            },
          ]
        },
        {
          name:"设备位置",
          id:10,
          isHidden:true,
          url:"/system/loaction_info",
          children:[
            {
              name:"设备位置",
              url:"/system/loaction_info",
            }
          ]
        },
        {
          name: '系统信息',
          id: 18,
          isHidden:true,
          url: '/system/system_setting',
          children: [
            {
              name: '系统设置',
              url: '/system/system_setting',
              value:"setting"
            }
          ]
        },
        {
          name:"日志信息",
          id:5,
          isHidden:true,
          url:"/system/log_info",
          children:[
            {
              name:"日志信息",
              url:"/system/log_info",
            }
          ]
        },
      ],
      myInfo:{},
      permissions: [
        'system:subject_table:create',
        'system:authority:create',
        'system:authority:update',
        'system:authority:delete',
        'system:authority:query',

        'system:carousel:create',

      ],
      alive_routes_ids:[],
      alive_routes:[],
      default_id:1,
      default_fid:1,
      socket_state:false
    };
  },
  getters: {},
  mutations: {
    loginOut(){
      router.push({
        path: "/login"
      })
    },
    changeToken(state, token: string) {
      state.token = token
    },
    changeAnalysisInfo(state,payload:any){
      state.analysisInfo = payload
    },
    changeHomeInfo(state,payload:any){
      state.homeInfo = payload
    },
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },
    changeCompanyInfo(state,company:any){
      state.company = company
    },
    changMySelfInfo(state, info: any) {
      let jurisdiction = JSON.parse(info)
      let menu = state.first_menus
      // console.log(jurisdiction,'看看职权',menu)
      if(!jurisdiction)return
      if(!jurisdiction.dateInfo_chair_header)
      {
        menu.splice(1,1)
        console.log('执法规范没有')
      }
      if(!jurisdiction.analysis)
      {
        menu.splice(0,1)
        console.log('大数据没有')
      }
      if(!jurisdiction.dateInfo_checkfaility_header)
      {
        menu.splice(2,1)
        console.log('智慧门禁没有')
      }
      if(!jurisdiction.setting)
      {
        menu.splice(3,1)
        console.log('系统设置没有没有')
      }
      menu.forEach((v:any) => {
          v.children.forEach((n:any,i:any)=>{
            if(!jurisdiction[n.value])
            {
              console.log(jurisdiction[n.value],n.value)
              v.children.splice(i,1)
            }
          })
      });
      state.first_menus = menu
      // console.log('查询一下个人的数据', state.myInfo)
    },
    changeUserMenus(state, userMenus: any) {
      // state.userMenus = userMenus
      // if(menus)
      // {
      //   state.userMenus[2].children = Object.values(menus)
      // }
      if(menus)
      {
        let allMenus = Object.values(menus)
        allMenus = sortKey(allMenus,'sort')
        allMenus.map(v=>{
          let index = menusList(state.userMenus,v)
          if(index!==-1)
          {
            console.log(index);
            state.userMenus[index].children.push(v)
          }
        })
        // state.userMenus[2].children = Object.values(menus)
      }
      // console.log('注册动态路由')
      // userMenus => routes
      const routes = mapMenusToRoutes(state.userMenus)
      // 将routes => router.main.children
      routes.forEach((route) => {
        router.addRoute('main', route)
      })

      // 获取用户按钮的权限
      // const permissions = mapMenusToPermissions(userMenus)
      // state.permissions = permissions
    },
    changRouteKeepLive(state,routerInfo){
      console.log(routerInfo,'看看要保存哪些路径')
      if(routerInfo.id==1){
      state.default_id = 1
      state.default_fid = 1
        return
      }
      let arr = state.alive_routes
      let arr_ids = state.alive_routes_ids
      state.default_id = routerInfo.id
      state.default_fid = routerInfo.parentId
      if(arr_ids.indexOf(routerInfo.id)!==-1 )return
      arr.push(routerInfo)
      arr_ids.push(routerInfo.id)
      state.alive_routes = arr
      state.alive_routes_ids = arr_ids
      // console.log(state.alive_routes,'看看当前保存了的路由')
    },
    changRouteKeepLiveDelete(state,routerInfo){
      // console.log('删除路由',routerInfo)
      let arr = state.alive_routes
      let arr_ids = state.alive_routes_ids
      let delete_index = null
      arr_ids.map((v:any,i:any)=>{
        console.log(v)
        if(v==routerInfo.id)
        {
          // console.log('存在路由',i)
          delete_index = i
          arr.splice(i,1)
          arr_ids.splice(i,1)

          if(Number(i)-1>0||Number(i)-1==0)
          {
            let new_index = Number(i)-1
            let new_url = arr[new_index].url
            state.default_id = arr[new_index].id
            router.push({
              path: new_url
            })
          }else{
            state.default_id = 1
            router.push({
              path: "/main/home"
            })
          }
        }
      })
      state.alive_routes = arr
      state.alive_routes_ids = arr_ids
      // state.default_id = state.alive_routes_ids[state.alive_routes_ids.length]
      // state.default_fid = state.alive_routes_ids[state.alive_routes_ids.length]
    }
  },
  actions: {
    accountLoginOut({ commit }) {
      localCache.deleteCache('token')
      localCache.deleteCache('ttac')
      commit('loginOut')
    },
    changeCompany({commit},payload){
      console.log(payload,'看看修改的是什么机构')
      commit('changeCompanyInfo',payload)
    },
    async handleGetAnalysisInfo({commit},payload){
      const pageUrl = `/custom/getanalysis/analysisinfo`
      // // 2.对页面发送请求
      const pageResult = await getPageList(pageUrl, payload.queryInfo)
          // console.log(pageResult);
          // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
          // // 3.将数据存储到state中
      const data = pageResult.data
      console.log(data,'查看请求的内容');
      commit('changeAnalysisInfo', data)
    },
    async handleIsReadReportInfo({commit},payload){
      const pageUrl = `/custom/update/facility_report`
      // // 2.对页面发送请求
      let {id} = payload
      console.log(payload,'看看需要修改的消息内容是')
      let param={
        id,
        re_mark:'已读'
      }
     await editPageData(pageUrl, param)
    },
    async handleIsReadNoticeInfo({commit},payload){
      const pageUrl = `/custom/update/notice`
      // // 2.对页面发送请求
      let {id} = payload
      console.log(payload,'看看需要修改的消息内容是')
      let param={
        id,
        user_name:'已读'
      }
     await editPageData(pageUrl, param)
    },
    async handleGetHomeInfo({commit},payload){
      const pageUrl = `/custom/gethome/homeinfo`
      // // 2.对页面发送请求
      const pageResult = await getPageList(pageUrl, payload.queryInfo)
          // console.log(pageResult);
          // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
          // // 3.将数据存储到state中
      const data = pageResult.data
      console.log(data,'查看请求的内容');
      commit('changeHomeInfo', data)
      // commit('change_emergency_conduct_Count', totalRecord)
    },
    async accountLoginIn({ commit }, payload: any) {
      console.log(payload);
      // router.push('/analysis/datedetail')
      // 1.实现登录逻辑
      const loginResult = await accountLoginRequest(payload)
      console.log(loginResult,'看看登录返回的内容');

      const { id, token,type,jurisdiction,company,company_id,name } = loginResult.data
      // console.log(token,id,type,'登录的结果',loginResult);
      commit('changeToken', token)
      // commit('changMySelfInfo', jurisdiction)
      commit('changeCompanyInfo',company)
      localCache.setCache('token', token)
      localCache.setCache('ttac', payload.account)
      if(type&&type==2)
      {
        // router.push('/system/facility_report')
        router.push('/analysisInfo')
        // commit('changeUserMenus')
      }
      let needsave:any = window.localStorage.getItem('remberkeys')
      if(token&&needsave==1)
      {
        console.log('登录成功')

        let account = Base64.encode(payload.account)
        let password = Base64.encode(payload.password)
        // console.log(account,'加密')
        // console.log(password,'加密')
        window.localStorage.setItem('sysUser',account)
        window.localStorage.setItem('sysPass',password)
      }

      // commit('loginIn',payload)
    },

    accountLoginAction({ commit }, payload: any) {
      // console.log('登陆里面的Action', payload)
      commit('changeUserMenus')
    },
    chooseRouterKeepLive({commit},payload:any){
      // console.log(payload,'看看点击了哪些页面')
      commit('changRouteKeepLive',payload.routeInfo)
    },
    deleteRouterKeepLive({commit},payload:any){
      // console.log('看看我删除的是哪个路由',payload)
      commit('changRouteKeepLiveDelete',payload.routeInfo)
    }
  },
};

export default loginModule;
