const modules:any = {};
import facilityModule from './facility';
modules['facilityModule'] = facilityModule

import facilitiesModule from './facilities';
modules['facilitiesModule'] = facilitiesModule

import facility_electroModule from './facility_electro';
modules['facility_electroModule'] = facility_electroModule

import facility_waterModule from './facility_water';
modules['facility_waterModule'] = facility_waterModule


import emergency_conductModule from './emergency_conduct';
modules['emergency_conductModule'] = emergency_conductModule

import emergency_schemeModule from './emergency_scheme';
modules['emergency_schemeModule'] = emergency_schemeModule

import error_fixedModule from './error_fixed';
modules['error_fixedModule'] = error_fixedModule

import facility_reportModule from './facility_report';
modules['facility_reportModule'] = facility_reportModule


import error_reportModule from './error_report';
modules['error_reportModule'] = error_reportModule

import location_infoModule from './location_info';
modules['location_infoModule'] = location_infoModule

import noticeModule from './notice';
modules['noticeModule'] = noticeModule

import operation_infoModule from './operation_info';
modules['operation_infoModule'] = operation_infoModule

import operation_informModule from './operation_inform';
modules['operation_informModule'] = operation_informModule

import operation_taskModule from './operation_task';
modules['operation_taskModule'] = operation_taskModule

import operation_userModule from './operation_user';
modules['operation_userModule'] = operation_userModule

import organization_detailModule from './organization_detail';
modules['organization_detailModule'] = organization_detailModule

import organization_userModule from './organization_user';
modules['organization_userModule'] = organization_userModule

import polling_recordModule from './polling_record';
modules['polling_recordModule'] = polling_recordModule

import polling_taskModule from './polling_task';
modules['polling_taskModule'] = polling_taskModule


import polling_taskmonthModule from './polling_taskmonth';
modules['polling_taskmonthModule'] = polling_taskmonthModule


import polling_tasktimeModule from './polling_tasktime';
modules['polling_tasktimeModule'] = polling_tasktimeModule


import polling_taskyearModule from './polling_taskyear';
modules['polling_taskyearModule'] = polling_taskyearModule


import polling_detailModule from './polling_recorddetail';
modules['polling_detailModule'] = polling_detailModule

import risk_organizationModule from './risk_organization';
modules['risk_organizationModule'] = risk_organizationModule

import risk_recordModule from './risk_record';
modules['risk_recordModule'] = risk_recordModule

import risk_recorduserModule from './risk_recorduser';
modules['risk_recorduserModule'] = risk_recorduserModule

import risk_userModule from './risk_user';
modules['risk_userModule'] = risk_userModule

import risk_userinfoModule from './risk_userinfo';
modules['risk_userinfoModule'] = risk_userinfoModule

import risk_settingModule from './risk_setting';
modules['risk_settingModule'] = risk_settingModule

import log_infoModule from './log_info';
modules['log_infoModule'] = log_infoModule

import provide_ageModule from './provide_age';
modules['provide_ageModule'] = provide_ageModule

import operation_typeModule from './operation_type';
modules['operation_typeModule'] = operation_typeModule

export { modules }
