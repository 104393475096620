const user_info = ()=>import('@/views/firesecurity/user_info/index.vue')
export default {
  path: '/system/user_info',
  name: 'user_info',
  component: user_info,
  meta:{
    pid:9,
    id:1,
    index:1,
  },
  children: []
}
