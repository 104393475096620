import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { checkfaility_state } from './type'
import { getPageList,editPageData } from "@/service/modules/systems";

const checkfailityModule:Module<checkfaility_state,IRootState>={
    namespaced:true,
    state(){
        return{
            checkfaility_List: [],
            checkfaility_Count: 0,//数据条数

        }
    },
    mutations:{
        change_checkfaility_List(state, checkfaility_List: any[]) {
            state.checkfaility_List = checkfaility_List
          },
          change_checkfaility_Count(state, checkfaility_Count: number) {
            state.checkfaility_Count = checkfaility_Count
          },
    },
    getters:{
        pageListData(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_List`]//选择对应数据
            }
          },
          pageListCount(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_Count`]//选择对应数据条数
            }
          }
    },
    actions:{
      async getPageListAction({ commit }, payload: any) {
         // // 1.获取pageUrl
        // // 1.获取pageUrl
        const pageName = payload.pageName
        const pageUrl = `/admin/get/facilitycheckinfo`

        // // 2.对页面发送请求
        const pageResult = await getPageList(pageUrl, payload.queryInfo)
        // console.log(pageResult);
        // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构

        // // 3.将数据存储到state中
        const { data, totalRecord } = pageResult.data
        console.log(data);
        commit('change_checkfaility_List',data)
        commit('change_checkfaility_Count',totalRecord)
      },
      async createPageDataAction({ dispatch }, payload: any) {
        // 1.创建数据的请求
        const { pageName, newData } = payload
        const pageUrl = `/admin/add/facilitycheckinfo`

        console.log('看看添加的用户',newData)
        // await createPageData(pageUrl, newData)
        await editPageData(pageUrl,newData)

        // 2.请求最新的数据
        dispatch('getPageListAction', {
          queryInfo: {}
        })
      },

      async editPageDataAction({ dispatch }, payload: any) {
        // 1.编辑数据的请求
        const { pageName, editData, id } = payload
        console.log(editData)
        const pageUrl = `/admin/edit/facilitycheckinfo`
        await editPageData(pageUrl, {...editData,id})

        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {}
        })
      },
      async deletePageDataAction({ dispatch }, payload: any) {
        // 1.获取pageName和id
        // pageName -> /users
        // id -> /users/id
        const { deleteData } = payload
        const pageUrl = `/admin/delete/facilitycheckinfo`
        let dataInfo = {
          id:deleteData.option
        }
        await editPageData(pageUrl, dataInfo)
        // 2.调用删除网络请求
        // await deletePageData(pageUrl)

        // 3.重新请求最新的数据
        dispatch('getPageListAction', {
          queryInfo: { }
        })
      },
    }
}

export default checkfailityModule
