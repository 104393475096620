const smart_electro = ()=>import('@/views/firesecurity/smart_electro/index.vue')
export default {
  path: '/system/smart_electro',
  name: 'smart_electro',
  component: smart_electro,
  meta:{
    pid:10,
    id:2,
    index:1,
  },
  children: []
}
