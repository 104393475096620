const systemModules:any = {};
import dataControllogModule from './dataInfoAll_controlllog';
systemModules['dataControllogModule'] = dataControllogModule

import errotipsModule from './dataInfoAll_errotips';
systemModules['errotipsModule'] = errotipsModule


import chairModule from './dateInfo_chair';
systemModules['chairModule'] = chairModule


import checkfailityModule from './dateInfo_checkfaility';
systemModules['checkfailityModule'] = checkfailityModule


import failityModule from './dateInfo_faility';
systemModules['failityModule'] = failityModule


import locationModule from './dateInfo_location';
systemModules['locationModule'] = locationModule


import reportModule from './dateInfo_report';
systemModules['reportModule'] = reportModule


import showinfoModule from './dateInfo_showinfo';
systemModules['showinfoModule'] = showinfoModule


import rondalogModule from './patrol_rondalog';
systemModules['rondalogModule'] = rondalogModule


import rondataskModule from './patrol_rondatask';
systemModules['rondataskModule'] = rondataskModule


import userplanModule from './schedule_userplan';
systemModules['userplanModule'] = userplanModule


import settingModule from './setting';
systemModules['settingModule'] = settingModule



export { systemModules }
