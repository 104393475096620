const loaction_info = ()=>import('@/views/firesecurity/loaction_info/index.vue')
export default {
  path: '/system/loaction_info',
  name: 'loaction_info',
  component: loaction_info,
  meta:{
    pid:13,
    id:1,
    index:1,
  },
  children: []
}
