const user_info_older = ()=>import('@/views/firesecurity/user_info_older/index.vue')
export default {
  path: '/system/user_info_older',
  name: 'user_info_older',
  component: user_info_older,
  meta:{
    pid:9,
    id:2,
    index:1,
  },
  children: []
}
