import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { notice_state } from './type'
import { getPageList, editPageData } from "@/service/modules/systems";

const noticeModule: Module < notice_state, IRootState >= {
    namespaced: true,
    state() {
        return {
            notice_List: [],
            notice_Count: 0, //数据条数
            text:""
        }
    },
    mutations: {
        change_notice_List(state, notice_List: any[]) {
            state.notice_List = notice_List
        },
        change_notice_Count(state, notice_Count: number) {
            state.notice_Count = notice_Count
        },
        changeText(state,payload:any){
          state.text = payload
          console.log(payload,'看看文本的内容 ')
        }
    },
    getters: {
        pageListData(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_List`] //选择对应数据
            }
        },
        pageListCount(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_Count`] //选择对应数据条数
            }
        }
    },
    actions: {
        async getPageListAction({ commit }, payload: any) {
            // // 1.获取pageUrl
            // // 1.获取pageUrl
            // const pageName = payload.pageName||''
            const pageUrl = `/custom/get/notice`
            // // 2.对页面发送请求
            let create_time = null
            if(payload.queryInfo)
            {
              if(payload.queryInfo.create_time)
              {
                create_time = JSON.stringify(payload.queryInfo.create_time)
              }
            }
            const pageResult = await getPageList(pageUrl, {...payload.queryInfo,create_time})
                // console.log(pageResult);
                // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
                // // 3.将数据存储到state中
            const { data, totalRecord } = pageResult.data
            console.log(data);
            commit('change_notice_List', data)
            commit('change_notice_Count', totalRecord)
        },
        async createPageDataAction({ dispatch,state }, payload: any) {
            // 1.创建数据的请求
            const { pageName, newData } = payload
            const pageUrl = `/custom/add/notice`

            console.log('看看添加的用户', payload,state.text,'内容')
            // newData.notice_type = '公告通知'
            newData.content = state.text
            await editPageData(pageUrl, newData)
                // await editPageData(pageUrl,payload)

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },

        async editPageDataAction({ dispatch }, payload: any) {
            // 1.编辑数据的请求
            const { pageName, editData, id } = payload
            console.log(editData)
            const pageUrl = `/custom/update/notice`
            await editPageData(pageUrl, {...editData, id })

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                pageName,
                queryInfo: {}
            })
        },
        async deletePageDataAction({ dispatch }, payload: any) {
            // 1.获取pageName和id
            // pageName -> /users
            // id -> /users/id
            const { deleteData } = payload
            console.log('我点击了解绑', deleteData)
            const pageUrl = `/custom/del/notice`
            let dataInfo = {
                id: deleteData.option
            }
            await editPageData(pageUrl, dataInfo)
                // 2.调用删除网络请求
                // await deletePageData(pageUrl)
                // 3.重新请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },
    }
}

export default noticeModule
