const commonRoutes:any = {}

import notice_table from "./notice_table"
commonRoutes['notice_table']=notice_table

import order_table from "./order_table"
commonRoutes['order_table']=order_table

import subject_table from "./subject_table"
commonRoutes['subject_table']=subject_table

import systems from "./systems"
commonRoutes['systems']=systems

import home from "./home"
commonRoutes['home']=home

import user  from "./user"
commonRoutes['user']=user

import authority from "./authority"
commonRoutes['authority']=authority


export { commonRoutes }
