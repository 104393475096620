import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { showinfo_state } from './type'
import { getPageList,editPageData } from "@/service/modules/systems";

const showinfoModule:Module<showinfo_state,IRootState>={
    namespaced:true,
    state(){
        return{
            showinfo_List: [],
            showinfo_Count: 0,//数据条数

        }
    },
    mutations:{
        change_showinfo_List(state, showinfo_List: any[]) {
            state.showinfo_List = showinfo_List
          },
          change_showinfo_Count(state, showinfo_Count: number) {
            state.showinfo_Count = showinfo_Count
          },
    },
    getters:{
        pageListData(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_List`]//选择对应数据
            }
          },
          pageListCount(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}_Count`]//选择对应数据条数
            }
          }
    },
    actions:{
      async getPageListAction({ commit }, payload: any) {
         // // 1.获取pageUrl
        // // 1.获取pageUrl
        // const pageName = payload.pageName
        const pageUrl = `/admin/get/showinfoinfo`

        // // 2.对页面发送请求
        const pageResult = await getPageList(pageUrl, payload.queryInfo)
        // console.log(pageResult);
        // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构

        // // 3.将数据存储到state中
        const { data, totalRecord } = pageResult.data
        console.log(data);
        commit('change_showinfo_List',data)
        commit('change_showinfo_Count',totalRecord)
      },
      async createPageDataAction({ dispatch }, payload: any) {
        // 1.创建数据的请求
        const { pageName, newData } = payload
        const pageUrl = `/admin/add/showinfoinfo`

        console.log('看看添加的用户',newData)
        // await createPageData(pageUrl, newData)
        await editPageData(pageUrl,{...newData})

        // 2.请求最新的数据
        dispatch('getPageListAction', {
          queryInfo: {}
        })
      },

      async editPageDataAction({ dispatch }, payload: any) {
        // 1.编辑数据的请求
        const { pageName, editData, id } = payload
        console.log(editData)
        const pageUrl = `/admin/edit/showinfoinfo`
        await editPageData(pageUrl, {...editData,id})

        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {}
        })
      },
      async updateFacilityInfo({ dispatch }, payload: any) {
        // 1.编辑数据的请求
        const {  editData } = payload
        // console.log(editData,'看看修改的继电器')
        const pageUrl = `/admin/update/showinfoinfo_facilityInfo`
        await editPageData(pageUrl, {...editData})

        // 2.请求最新的数据
        // dispatch('getPageListAction', {
        //   pageName,
        //   queryInfo: {}
        // })
      },
      async deletePageDataAction({ dispatch }, payload: any) {
        // 1.获取pageName和id
        // pageName -> /users
        // id -> /users/id
        const { deleteData } = payload
        let dataInfo = {
          id:deleteData.option
        }
        const pageUrl = `/admin/delete/showinfoinfo`
        await editPageData(pageUrl, dataInfo)
        // 2.调用删除网络请求
        // await deletePageData(pageUrl)

        // 3.重新请求最新的数据
        dispatch('getPageListAction', {
          queryInfo: { }
        })
      },
    }
}

export default showinfoModule
