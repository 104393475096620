const facilities_manager = ()=>import('@/views/firesecurity/facilities_manager/index.vue')
export default {
  path: '/system/facilities_manager',
  name: 'facilities_manager',
  component: facilities_manager,
  meta:{
    pid:11,
    id:1,
    index:1,
  },
  children: []
}
