const error_fixed = ()=>import('@/views/firesecurity/error_fixed/index.vue')
export default {
  path: '/system/error_fixed',
  name: 'error_fixed',
  component: error_fixed,
  meta:{
    pid:4,
    id:1,
    index:0,
  },
  children: []
}
