import { createStore, Store, useStore as useVuexStore  } from 'vuex';

import { IRootState, IStoreType } from './types';
import login from './login';import systemModule from './main/systems';import userModule from './main/users';import authorityModule from './common/authority';
import order_tableModule from './common/order_table';
import notice_tableModule from './common/notice_table';
import subject_tableModule from './common/subject_table';
import order_text_tableModule from './common/order_text_table'
import { modules } from './modules';
import {systemModules} from './system';
const store = createStore<IRootState>({
  state() {
    return {
      storeInfo: '这是vuex里面的东西',
      name: '',
      age: 0,
      entireDepartment: [],
      entireRole: [],
      entireMenu: []
    };
  },
  getters: {},
  mutations: {
    changeEntireDepartment(state, list) {
      state.entireDepartment = list
    },
    changeEntireRole(state, list) {
      state.entireRole = list
    },
    changeEntireMenu(state, list) {
      state.entireMenu = list
    }
  },
  actions: {
    async getInitialDataAction({ commit }) {
      // 1.请求部门和角色数据
      // const departmentResult = await getPageListData('/department/list', {
      //   offset: 0,
      //   size: 1000
      // })
      // const { list: departmentList } = departmentResult.data
      // const roleResult = await getPageListData('/role/list', {
      //   offset: 0,
      //   size: 1000
      // })
      // const { list: roleList } = roleResult.data
      // const menuResult = await getPageListData('/menu/list', {})
      // const { list: menuList } = menuResult.data
      const departmentList = {}
      // 2.保存数据
      commit('changeEntireDepartment', departmentList)
      // commit('changeEntireRole', roleList)
      // commit('changeEntireMenu', menuList)
    }
  },
  modules:{...systemModules,...modules,login,systemModule,userModule,order_tableModule,notice_tableModule,subject_tableModule,order_text_tableModule,authorityModule}

});
export function setupStore() {
  store.dispatch('login/accountLoginAction')
  // store.dispatch('getInitialDataAction')
}

export function useStore(): Store<IStoreType> {
  return useVuexStore()
}

export default store;
