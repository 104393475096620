const polling_task_time = ()=>import('@/views/firesecurity/polling_task_time/index.vue')
export default {
  path: '/system/polling_task_time',
  name: 'polling_task_time',
  component: polling_task_time,
  meta:{
    pid:3,
    id:1,
    index:0,
  },
  children: []
}
