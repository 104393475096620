const emergency_scheme = ()=>import('@/views/firesecurity/emergency_scheme/index.vue')
export default {
  path: '/system/emergency_scheme',
  name: 'emergency_scheme',
  component: emergency_scheme,
  meta:{
    pid:5,
    id:2,
    index:0,
  },
  children: []
}
