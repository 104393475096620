import { ElAside,
  ElAvatar,
  ElButton,
  ElCheckbox,
  ElContainer,
  ElDatePicker,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElForm,
  ElFormItem,
  ElHeader,
  ElInput,
  ElLink,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElOption,
  ElRadio,
  ElRow,
  ElCol,
  ElSelect,
  ElSubMenu,
  ElTabPane,
  ElTabs,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElImage,
  ElDialog,
  ElTree,
  ElConfigProvider,
  ElUpload,
  ElPopover,
  ElTimePicker,
  ElCard,
  ElSwitch,
  ElTag,
  ElRadioGroup,
  ElCascaderPanel,
  ElBadge,
  ElIcon,
  ElCheckboxGroup,
  ElCalendar,
  ElCascader,
} from 'element-plus/lib/index'
import 'element-plus/theme-chalk/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const components = [
  ElAvatar,
  ElButton,
  ElConfigProvider,
  ElCheckbox,
  ElContainer,
  ElDatePicker,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElForm,
  ElFormItem,
  ElHeader,
  ElInput,
  ElLink,
  ElMain,
  ElAside,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElOption,
  ElRadio,
  ElRow,
  ElCol,
  ElSelect,
  ElSubMenu,
  ElTabPane,
  ElTabs,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElTable,
  ElTableColumn,
  ElPagination,
  ElImage,
  ElDialog,
  ElTree,
  ElUpload,
  ElPopover,
  ElTimePicker,
  ElCard,
  ElSwitch,
  ElTag,
  ElRadioGroup,
  ElCascaderPanel,
  ElBadge,
  ElIcon,
  ElCheckboxGroup,
  ElCalendar,
  ElCascader
]

export default function(app:any):void{
  for(const component of components){
    app.component(component.name,component)
  }
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

}
