import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { provide_age_state } from './type'
import { getPageList, editPageData } from "@/service/modules/systems";

const provide_ageModule: Module < provide_age_state, IRootState >= {
    namespaced: true,
    state() {
        return {
            provide_age_List: [],
            provide_age_Count: 0, //数据条数
        }
    },
    mutations: {
        change_provide_age_List(state, provide_age_List: any[]) {
            state.provide_age_List = provide_age_List
        },
        change_provide_age_Count(state, provide_age_Count: number) {
            state.provide_age_Count = provide_age_Count
        },
    },
    getters: {
        pageListData(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_List`] //选择对应数据
            }
        },
        pageListCount(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_Count`] //选择对应数据条数
            }
        }
    },
    actions: {
        async getPageListAction({ commit }, payload: any) {
            // // 1.获取pageUrl
            // // 1.获取pageUrl
            // const pageName = payload.pageName||''
            const pageUrl = `/custom/get/provide_age`
            // // 2.对页面发送请求
            const pageResult = await getPageList(pageUrl, payload.queryInfo)
                // console.log(pageResult);
                // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
                // // 3.将数据存储到state中
            const { data, totalRecord } = pageResult.data
            console.log(data);
            commit('change_provide_age_List', data)
            commit('change_provide_age_Count', totalRecord)
        },
        async createPageDataAction({ dispatch }, payload: any) {
            // 1.创建数据的请求
            const { pageName, newData } = payload
            const pageUrl = `/custom/add/provide_age`

            console.log('看看添加的用户', payload)
            await editPageData(pageUrl, newData)
                // await editPageData(pageUrl,payload)

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },

        async editPageDataAction({ dispatch }, payload: any) {
            // 1.编辑数据的请求
            const { pageName, editData, id } = payload
            console.log(editData)
            const pageUrl = `/custom/update/provide_age`
            await editPageData(pageUrl, {...editData, id })

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                pageName,
                queryInfo: {}
            })
        },
        async deletePageDataAction({ dispatch }, payload: any) {
            // 1.获取pageName和id
            // pageName -> /users
            // id -> /users/id
            const { deleteData } = payload
            console.log('我点击了解绑', deleteData)
            const pageUrl = `/custom/del/provide_age`
            let dataInfo = {
                id: deleteData.option
            }
            await editPageData(pageUrl, dataInfo)
                // 2.调用删除网络请求
                // await deletePageData(pageUrl)
                // 3.重新请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },
        async uploadExcelDataAction({ dispatch }, payload: any) {
          let allExcelList = payload.original_dataInfo
          let dataInfo = payload.dataInfo
          let pageName = payload.pageName
          console.log(payload,'尺寸参数')
          const pageUrl = `/user/uploadExcel`
          let res = await editPageData(pageUrl, {
            data: dataInfo,
            pageName
          })
          console.log(res,'看看导入的结果')
          dispatch('getPageListAction', {
            queryInfo: {}
          })
        }
    }
}

export default provide_ageModule
