import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { polling_task_state } from './type'
import { getPageList, editPageData } from "@/service/modules/systems";

const polling_taskModule: Module < polling_task_state, IRootState >= {
    namespaced: true,
    state() {
        return {
            polling_task_List: [],
            polling_task_Count: 0, //数据条数
            polling_tree_List:{},
            polling_tree_faility:{}
        }
    },
    mutations: {
      updateFaility(state, polling_tree_faility: any) {
        state.polling_tree_faility = polling_tree_faility
    },
      updateTreeList(state, polling_tree_List: any) {
        state.polling_tree_List = polling_tree_List
    },
        change_polling_task_List(state, polling_task_List: any[]) {
            state.polling_task_List = polling_task_List
        },
        change_polling_task_Count(state, polling_task_Count: number) {
            state.polling_task_Count = polling_task_Count
        },
    },
    getters: {
        pageListData(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_List`] //选择对应数据
            }
        },
        pageListCount(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_Count`] //选择对应数据条数
            }
        },
        companytreeList(state){
            return async (payload:any)=>{
              const pageUrl = `/custom/get/companytreelist`
              let res = await editPageData(pageUrl, payload)
              return res
            }
        }
    },
    actions: {
        async getPageListAction({ commit }, payload: any) {
            // // 1.获取pageUrl
            // // 1.获取pageUrl
            // const pageName = payload.pageName||''
            const pageUrl = `/custom/get/polling_task`
            // // 2.对页面发送请求
            let create_time = null
            if(payload.queryInfo)
            {
              if(payload.queryInfo.create_time)
              {
                create_time = JSON.stringify(payload.queryInfo.create_time)
              }
            }
            const pageResult = await getPageList(pageUrl, {...payload.queryInfo,create_time})
                // console.log(pageResult);
                // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
                // // 3.将数据存储到state中

            const { data, totalRecord } = pageResult.data
            console.log(data);
            commit('change_polling_task_List', data)
            commit('change_polling_task_Count', totalRecord)
        },
        async createPageDataAction({ dispatch }, payload: any) {
            // 1.创建数据的请求
            const { pageName, newData } = payload
            const pageUrl = `/custom/add/polling_task`
            console.log('看看添加的用户', payload)
            let info = Object.values(newData).find(v=>!v)
            console.log(info,'看看是否存在undefin')
            await editPageData(pageUrl, newData)
            // await editPageData(pageUrl,payload)

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },
        async checkTreeDataFailityAction({dispatch,commit},payload:any){
          const pageUrl = `/custom/get/failitytreelist`
          let res = await editPageData(pageUrl, payload)
          let data = res.data
          console.log(data,'看看设备树状图',payload)
          commit('updateFaility',data)
        },
        async checkTreeDataAction({dispatch,commit},payload:any){
          const pageUrl = `/custom/get/companytreelist`
          let res = await editPageData(pageUrl, payload)
          let data = res.data
          console.log(data,'看看选择的结果')
          commit('updateTreeList',data)
        },
        async editPageDataAction({ dispatch }, payload: any) {
            // 1.编辑数据的请求
            const { pageName, editData, id } = payload
            console.log(editData)
            const pageUrl = `/custom/update/polling_task`
            await editPageData(pageUrl, {...editData, id })

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                pageName,
                queryInfo: {}
            })
        },
        async deletePageDataAction({ dispatch }, payload: any) {
            // 1.获取pageName和id
            // pageName -> /users
            // id -> /users/id
            const { deleteData } = payload
            console.log('我点击了解绑', deleteData)
            const pageUrl = `/custom/del/polling_task`
            let dataInfo = {
                id: deleteData.option
            }
            await editPageData(pageUrl, dataInfo)
                // 2.调用删除网络请求
                // await deletePageData(pageUrl)
                // 3.重新请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },
    }
}

export default polling_taskModule
