const maintain_voice_record = ()=>import('@/views/firesecurity/maintain_voice_record/index.vue')
export default {
  path: '/system/maintain_voice_record',
  name: 'maintain_voice_record',
  component: maintain_voice_record,
  meta:{
    pid:12,
    id:2,
    index:1,
  },
  children: []
}
