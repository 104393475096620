import ycx_Request from "../../index";
import { IDataType } from "../../types";

export function getPageList(url:string,queryInfo:any){
    return ycx_Request.get<IDataType>({
        url:url,
        params:queryInfo
    })
}
export function deletePageData(url: string) {
    return ycx_Request.delete<IDataType>({
      url: url
    })
  }

  export function createPageData(url: string, newData: any) {
    return ycx_Request.post<IDataType>({
      url: url,
      data: newData
    })
  }

  export function editPageData(url: string, editData: any) {
    return ycx_Request.post<IDataType>({
      url: url,
      data: editData
    })
  }
