import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { ISystemState } from './types'

const systemModule:Module<ISystemState,IRootState>={
    namespaced:true,
    state(){
        return{
            usersList: [
                {
                    name:'测试',
                    realname:'测试信息',
                    cellphone:16666666666,
                    enable:1,
                }
            ],
            usersCount: 0,//数据条数
            roleList: [],//对应数据
            roleCount: 0,//数据条数
            goodsList: [],//对应数据
            goodsCount: 0,//数据条数
            menuList: [],//对应数据
            menuCount: 0//数据条数
        }
    },
    mutations:{
        changeUsersList(state, userList: any[]) {
            state.usersList = userList
          },
          changeUsersCount(state, userCount: number) {
            state.usersCount = userCount
          },
          changeRoleList(state, list: any[]) {
            state.roleList = list
          },
          changeRoleCount(state, count: number) {
            state.roleCount = count
          },
          changeGoodsList(state, list: any[]) {
            state.goodsList = list
          },
          changeGoodsCount(state, count: number) {
            state.goodsCount = count
          },
          changeMenuList(state, list: any[]) {
            state.menuList = list
          },
          changeMenuCount(state, count: number) {
            state.menuCount = count
          }
    },
    getters:{
        pageListData(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}List`]//选择对应数据
            }
          },
          pageListCount(state) {
            return (pageName: string) => {
              return (state as any)[`${pageName}Count`]//选择对应数据条数
            }
          }
    },
    actions:{
      async getPageListAction({ commit }, payload: any) {
        // // 1.获取pageUrl
        // const pageName = payload.pageName
        // const pageUrl = `/${pageName}/list`
  
        // // 2.对页面发送请求
        // // const pageResult = await getPageListData(pageUrl, payload.queryInfo)
        // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
  
        // // 3.将数据存储到state中
        // const { list, totalCount } = pageResult.data
  
        // const changePageName =
        //   pageName.slice(0, 1).toUpperCase() + pageName.slice(1)
        // commit(`change${changePageName}List`, list)
        // commit(`change${changePageName}Count`, totalCount)
      },
      async createPageDataAction({ dispatch }, payload: any) {
        // 1.创建数据的请求
        const { pageName, newData } = payload
        const pageUrl = `/${pageName}`
        // await createPageData(pageUrl, newData)
  
        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {
            offset: 0,
            size: 10
          }
        })
      },
  
      async editPageDataAction({ dispatch }, payload: any) {
        // 1.编辑数据的请求
        const { pageName, editData, id } = payload
        console.log(editData)
        const pageUrl = `/${pageName}/${id}`
        // await editPageData(pageUrl, editData)
  
        // 2.请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {
            offset: 0,
            size: 10
          }
        })
      },
      async deletePageDataAction({ dispatch }, payload: any) {
        // 1.获取pageName和id
        // pageName -> /users
        // id -> /users/id
        const { pageName, id } = payload
        const pageUrl = `/${pageName}/${id}`
  
        // 2.调用删除网络请求
        // await deletePageData(pageUrl)
  
        // 3.重新请求最新的数据
        dispatch('getPageListAction', {
          pageName,
          queryInfo: {
            offset: 0,
            size: 10
          }
        })
      },
    }
}

export default systemModule