
export function menusList(state:any,menus?:any){
    // console.log(menus.parentId);

    let typeMenus = state
    let typeIndex:any = {}
    let allParent:any = []
    typeMenus.map((v:any,i:any)=>{
        if(v.type==1)
        {
            typeIndex[v.id] = i
            allParent.push(v.id)
        }
    })
    // console.log(allParent);

    if(allParent.indexOf(menus.parentId)!==-1)
    {
        // console.log(menus)
        return typeIndex[menus.parentId]
    }
    // console.log(typeIndex)
}
export function sortKey(array: any, key: any) {
    return array.sort(function (a: any, b: any) {
      var x = a[key]; //如果要从大到小,把x,y互换就好
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  export function removeEmpty(obj: any) {
    for (let i in obj) {
      if (!obj[i]&&obj[i]!==0) {
        delete obj[i]
      }
    }
    return obj
  }
