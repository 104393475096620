const systemsRouter:any = {}

import pages_zxgk from "./pages_zxgk"
systemsRouter['pages_zxgk']=pages_zxgk


import pages_zxgk_2 from "./pages_zxgk_2"
systemsRouter['pages_zxgk_2']=pages_zxgk_2


import pages_zxgk_3 from "./pages_zxgk_3"
systemsRouter['pages_zxgk_3']=pages_zxgk_3


import pages_zxgk_4 from "./pages_zxgk_4"
systemsRouter['pages_zxgk_4']=pages_zxgk_4


import pages_zxgk_5 from "./pages_zxgk_5"
systemsRouter['pages_zxgk_5']=pages_zxgk_5


import pages_zxgk_6 from "./pages_zxgk_6"
systemsRouter['pages_zxgk_6']=pages_zxgk_6

import pages_zxgk_7 from "./pages_zxgk_7"
systemsRouter['pages_zxgk_7']=pages_zxgk_7

import pages_zxgk_8 from "./pages_zxgk_8"
systemsRouter['pages_zxgk_8']=pages_zxgk_8

import pages_zxgk_9 from "./pages_zxgk_9"
systemsRouter['pages_zxgk_9']=pages_zxgk_9


import pages_zxgk_10 from "./pages_zxgk_10"
systemsRouter['pages_zxgk_10']=pages_zxgk_10


import pages_zxgk_11 from "./pages_zxgk_11"
systemsRouter['pages_zxgk_11']=pages_zxgk_11


import pages_zxgk_12 from "./pages_zxgk_12"
systemsRouter['pages_zxgk_12']=pages_zxgk_12

import pages_zxgk_13 from "./pages_zxgk_13"
systemsRouter['pages_zxgk_13']=pages_zxgk_13


import pages_zxgk_14 from "./pages_zxgk_14"
systemsRouter['pages_zxgk_14']=pages_zxgk_14


import pages_zxgk_15 from "./pages_zxgk_15"
systemsRouter['pages_zxgk_15']=pages_zxgk_15

export {systemsRouter}
