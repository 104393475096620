const water_gage = ()=>import('@/views/firesecurity/water_gage/index.vue')
export default {
  path: '/system/water_gage',
  name: 'water_gage',
  component: water_gage,
  meta:{
    pid:10,
    id:3,
    index:1,
  },
  children: []
}
