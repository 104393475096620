const firesecurityRouter:any = {}

import ai_report from "./ai_report"
firesecurityRouter['ai_report']=ai_report


import emergency_conduct from "./emergency_conduct"
firesecurityRouter['emergency_conduct']=emergency_conduct


import emergency_scheme from "./emergency_scheme"
firesecurityRouter['emergency_scheme']=emergency_scheme


import error_fixed from "./error_fixed"
firesecurityRouter['error_fixed']=error_fixed


import facility_action from "./facility_action"
firesecurityRouter['facility_action']=facility_action


import facility_manager from "./facility_manager"
firesecurityRouter['facility_manager']=facility_manager

import facility_report from "./facility_report"
firesecurityRouter['facility_report']=facility_report

import institutional_information from "./institutional_information"
firesecurityRouter['institutional_information']=institutional_information

import water_gage from "./water_gage"
firesecurityRouter['water_gage']=water_gage


import loaction_info from "./loaction_info"
firesecurityRouter['loaction_info']=loaction_info


import log_info from "./log_info"
firesecurityRouter['log_info']=log_info


import user_info from "./user_info"
firesecurityRouter['user_info']=user_info

import maintain_manager from "./maintain_manager"
firesecurityRouter['maintain_manager']=maintain_manager


import maintain_voice_recharge from "./maintain_voice_recharge"
firesecurityRouter['maintain_voice_recharge']=maintain_voice_recharge


import maintain_voice_record from "./maintain_voice_record"
firesecurityRouter['maintain_voice_record']=maintain_voice_record


import notice from "./notice"
firesecurityRouter['notice']=notice


import performance_assessment from "./performance_assessment"
firesecurityRouter['performance_assessment']=performance_assessment


import system_setting from "./system_setting"
firesecurityRouter['system_setting']=system_setting


import polling_record from "./polling_record"
firesecurityRouter['polling_record']=polling_record


import polling_task_month from "./polling_task_month"
firesecurityRouter['polling_task_month']=polling_task_month

import polling_task_time from "./polling_task_time"
firesecurityRouter['polling_task_time']=polling_task_time

import polling_task_year from "./polling_task_year"
firesecurityRouter['polling_task_year']=polling_task_year

import risk_assessment from "./risk_assessment"
firesecurityRouter['risk_assessment']=risk_assessment

import risk_performance from "./risk_performance"
firesecurityRouter['risk_performance']=risk_performance

import smart_ai from "./smart_ai"
firesecurityRouter['smart_ai']=smart_ai

import smart_electro from "./smart_electro"
firesecurityRouter['smart_electro']=smart_electro

import user_info_older from "./user_info_older"
firesecurityRouter['user_info_older']=user_info_older


import error_report from "./error_report"
firesecurityRouter['error_report']=error_report


import facilities_manager from "./facilities_manager"
firesecurityRouter['facilities_manager']=facilities_manager


import analysis from "./analysis"
firesecurityRouter['analysis']=analysis

import datacenter from "./datacenter"
firesecurityRouter['datacenter']=datacenter


export {firesecurityRouter}
