const system_setting = ()=>import('@/views/firesecurity/system_setting/index.vue')
export default {
  path: '/system/system_setting',
  name: 'system_setting',
  component: system_setting,
  meta:{
    pid:14,
    id:1,
    index:1,
  },
  children: []
}
