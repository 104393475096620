const error_report = ()=>import('@/views/firesecurity/error_report/index.vue')
export default {
  path: '/system/error_report',
  name: 'error_report',
  component: error_report,
  meta:{
    pid:2,
    id:2,
    index:0,
  },
  children: []
}
