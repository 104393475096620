import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { operation_type_state } from './type'
import { getPageList, editPageData } from "@/service/modules/systems";

const operation_typeModule: Module < operation_type_state, IRootState >= {
    namespaced: true,
    state() {
        return {
            operation_type_List: [],
            operation_type_Count: 0, //数据条数
            operation_type_out:[]
        }
    },
    mutations: {
        changeOutList(state,payload:any){
          state.operation_type_out = payload
        },
        change_operation_type_List(state, operation_type_List: any[]) {
            state.operation_type_List = operation_type_List
        },
        change_operation_type_Count(state, operation_type_Count: number) {
            state.operation_type_Count = operation_type_Count
        },
    },
    getters: {
        pageListData(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_List`] //选择对应数据
            }
        },
        pageListCount(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_Count`] //选择对应数据条数
            }
        }
    },
    actions: {
        async getPageListAction({ commit }, payload: any) {
            // // 1.获取pageUrl
            // // 1.获取pageUrl
            // const pageName = payload.pageName||''
            const pageUrl = `/custom/get/operation_type`
            // // 2.对页面发送请求
            if(!payload)
            {
              payload = {}
              payload.queryInfo = {}
            }
            const pageResult = await getPageList(pageUrl, payload.queryInfo)
                // console.log(pageResult);
                // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
                // // 3.将数据存储到state中
            const { data, totalRecord } = pageResult.data
            console.log(data);
            commit('change_operation_type_List', data)
            commit('change_operation_type_Count', totalRecord)
        },
        async createPageDataAction({ dispatch,state }, payload: any) {
            // 1.创建数据的请求
            const { pageName, newData } = payload
            let pageUrl = `/custom/add/operation_type`
            let isUpdate = false
            let needAdd = true
            let data_info = state.operation_type_List
            console.log(payload,'看看消息')
            data_info.map(v=>{
              if(v.operation_company==payload.operation_company&&v.operation_type==payload.operation_type)
              {
                needAdd = false
              }
              console.log(v,'看看消息')
              // if(v.label==payload.label&&v.operation_type==payload.operation_type)
              // {
              //   if(v.respond_level.indexOf(payload.respond_level)!==-1)
              //   {
              //     needAdd = false
              //     return
              //   }else{
              //     isUpdate = true
              //     console.log(v)
              //     let respond_level = [...JSON.parse(v.respond_level)]
              //     console.log(respond_level)
              //     payload.id = v.id
              //     // payload.respond_level = respond_level.push(respond_level)
              //     console.log( payload.respond_level)
              //     respond_level.push(payload.respond_level)
              //     console.log(respond_level)
              //     payload.respond_level = JSON.stringify(respond_level)
              //     // v.respond_level = JSON.stringify(JSON.parse(v.respond_level).push(payload.respond_level))
              //     // payload = v
              //   }
              // }
            })
            // return
            // if(isUpdate)
            // {
            //   pageUrl = `/custom/update/operation_type`
            //   console.log(payload)
            //   // return
            // }else{
            //   payload.respond_level = JSON.stringify([payload.respond_level])
            // }
            if(!needAdd)return
            console.log('看看添加的用户', payload)
            await editPageData(pageUrl, payload)
                // await editPageData(pageUrl,payload)

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },

        async editPageDataAction({ dispatch }, payload: any) {
            // 1.编辑数据的请求
            const { pageName, editData, id } = payload
            console.log(editData)
            const pageUrl = `/custom/update/operation_type`
            await editPageData(pageUrl, {...editData, id })

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                pageName,
                queryInfo: {}
            })
        },
        async deletePageDataAction({ dispatch }, payload: any) {
            // 1.获取pageName和id
            // pageName -> /users
            // id -> /users/id
            const { deleteData } = payload
            console.log('我点击了解绑', deleteData)
            const pageUrl = `/custom/del/operation_type`
            let dataInfo = {
                id: deleteData.option
            }
            await editPageData(pageUrl, dataInfo)
                // 2.调用删除网络请求
                // await deletePageData(pageUrl)
                // 3.重新请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },
    }
}

export default operation_typeModule
