const risk_assessment = ()=>import('@/views/firesecurity/risk_assessment/index.vue')
export default {
  path: '/system/risk_assessment',
  name: 'risk_assessment',
  component: risk_assessment,
  meta:{
    pid:7,
    id:1,
    index:1,
  },
  children: []
}
