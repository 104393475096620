const institutional_information = ()=>import('@/views/firesecurity/institutional_information/index.vue')
export default {
  path: '/system/institutional_information',
  name: 'institutional_information',
  component: institutional_information,
  meta:{
    pid:8,
    id:1,
    index:1,
  },
  children: []
}
