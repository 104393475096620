const risk_performance = ()=>import('@/views/firesecurity/risk_performance/index.vue')
export default {
  path: '/system/risk_performance',
  name: 'risk_performance',
  component: risk_performance,
  meta:{
    pid:7,
    id:4,
    index:1,
  },
  children: []
}
