import { createRouter,createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { firstMenu } from '@/utils/map-menus'
import { commonRoutes } from './common'
import {systemRouter} from './system'
import { systemsRouter } from './systems'
import { analysisRouter } from './analysis'
import {firesecurityRouter} from './firesecurity'
import localCache from '@/utils/cache'
import { ElMessage } from 'element-plus'
import { getPageList, editPageData } from "@/service/modules/systems";
const routes:RouteRecordRaw[] = [
  {path:"/",redirect:'/login'},
  {path:"/login",name:'login',component:()=>import('../views/login/index.vue')},
  {path:"/main",name:'main',component:()=>import('../views/main/index.vue')},
  {path:"/systemInfo",name:'systemInfo',component:()=>import('../views/system/index.vue')},
  {path:"/analysis",name:'analysis',component:()=>import('../views/analysis/index.vue')},
  {path:"/schema",name:'schema',component:()=>import('../views/schema/index.vue')},
  {path:"/analysisInfo",name:'analysisInfo',component:()=>import('../views/firesecurity/analysis/index.vue')},
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/nofound/index.vue')
  }
]

const router = createRouter({
  routes,
  history:createWebHashHistory()
})
Object.values(commonRoutes).map((item:any)=>{
  // console.log(item,'看看内容')
  router.addRoute('main', item)
})
// Object.values(systemRouter).map((item:any)=>{
//   // console.log(item,'看看内容')
//   router.addRoute('systemInfo', item)
// })
Object.values(systemsRouter).map((item:any)=>{
  // console.log(item,'看看内容')
  router.addRoute('systemInfo', item)
})
Object.values(firesecurityRouter).map((item:any)=>{
  // console.log(item,'看看内容')
  router.addRoute('systemInfo', item)
})

// 导航守卫
router.beforeEach(async (to) => {
  if (to.path !== '/login') {
    const token = localCache.getCache('token')
    let myInfo = await getPageList('/admin/checkuserinfo',{})
    console.log(myInfo,'看看个人信息')
    if (!token) {
      ElMessage({
        message:'未登录,请登录后再试',
        type: 'warning',
      })
      return '/login'
    }
  }

  // console.log(router.getRoutes())
  // console.log(to) // route对象

  if (to.path === '/main') {
    // console.log(firstMenu)
    return firstMenu.url
  }
})
export default router
