import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { polling_detail_state } from './type'
import { getPageList, editPageData } from "@/service/modules/systems";

const polling_detailModule: Module < polling_detail_state, IRootState >= {
    namespaced: true,
    state() {
        return {
            polling_detail_List: [],
            polling_detail_Count: 0, //数据条数

        }
    },
    mutations: {
        change_polling_detail_List(state, polling_detail_List: any[]) {
            state.polling_detail_List = polling_detail_List
        },
        change_polling_detail_Count(state, polling_detail_Count: number) {
            state.polling_detail_Count = polling_detail_Count
        },
    },
    getters: {
        pageListData(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_List`] //选择对应数据
            }
        },
        pageListCount(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_Count`] //选择对应数据条数
            }
        }
    },
    actions: {
      async getPageListAction({ commit }, payload: any) {
        // // 1.获取pageUrl
        // // 1.获取pageUrl
        // const pageName = payload.pageName||''
        const pageUrl = `/custom/get/polling_detail`
        // // 2.对页面发送请求
        console.log(payload.queryInfo,'看看巡检处理的内容')
        if(payload.queryInfo&&!payload.queryInfo.task_id)
        {
          commit('change_polling_detail_List', [])
          commit('change_polling_detail_Count', 0)
          return
        }
        const pageResult = await getPageList(pageUrl, payload.queryInfo)
            // console.log(pageResult);
            // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
            // // 3.将数据存储到state中
        const { data, totalRecord } = pageResult.data
        console.log(data);
        commit('change_polling_detail_List', data)
        commit('change_polling_detail_Count', totalRecord)
    },
    async createPageDataAction({ dispatch }, payload: any) {
        // 1.创建数据的请求
        const { pageName, newData } = payload
        const pageUrl = `/custom/add/polling_detail`

        console.log('看看添加的用户', payload)
        await editPageData(pageUrl, newData)
            // await editPageData(pageUrl,payload)

        // 2.请求最新的数据
        dispatch('getPageListAction', {
            queryInfo: {}
        })
    },

    async editPageDataAction({ dispatch }, payload: any) {
        // 1.编辑数据的请求
        const { pageName, editData, id } = payload
        console.log(editData)
        const pageUrl = `/custom/update/polling_detail`
        await editPageData(pageUrl, {...editData, id })

        // 2.请求最新的数据
        dispatch('getPageListAction', {
            pageName,
            queryInfo: {}
        })
    },
    async deletePageDataAction({ dispatch }, payload: any) {
        // 1.获取pageName和id
        // pageName -> /users
        // id -> /users/id
        const { deleteData } = payload
        console.log('我点击了解绑', deleteData)
        const pageUrl = `/custom/del/polling_detail`
        let dataInfo = {
            id: deleteData.option
        }
        await editPageData(pageUrl, dataInfo)
            // 2.调用删除网络请求
            // await deletePageData(pageUrl)
            // 3.重新请求最新的数据
        dispatch('getPageListAction', {
            queryInfo: {}
        })
    },
    }
}

export default polling_detailModule
