const polling_record = ()=>import('@/views/firesecurity/polling_record/index.vue')
export default {
  path: '/system/polling_record',
  name: 'polling_record',
  component: polling_record,
  meta:{
    pid:3,
    id:2,
    index:0,
  },
  children: []
}
