const menus:any = {}

// import {teacher_table} from './teacher_table';
//  menus['teacher_table']=teacher_table

// import {student_table} from './student_table';
//  menus['student_table']=student_table

// import {order_table} from './order_table';
//  menus['order_table']=order_table

// import {excises_table} from './excises_table';
//  menus['excises_table']=excises_table

// import {favorite_table} from './favorite_table';
//  menus['favorite_table']=favorite_table

// import {product_table} from './product_table';
//  menus['product_table']=product_table

// import {course_table} from './course_table';
//  menus['course_table']=course_table

export { menus }
