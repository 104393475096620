const maintain_manager = ()=>import('@/views/firesecurity/maintain_manager/index.vue')
export default {
  path: '/system/maintain_manager',
  name: 'maintain_manager',
  component: maintain_manager,
  meta:{
    pid:12,
    id:1,
    index:1,
  },
  children: []
}
