import axios from "axios";

import type { AxiosInstance } from "axios";

import type {YCX_Interceptors,YCX_RequestConfig} from './type'

import { ElLoading } from 'element-plus'
import { LoadingInstance } from "element-plus/lib/components/loading/src/loading";

const DEAFULT_LOADING = true


class YCX_Request{
  instance:AxiosInstance
  interceptors?:YCX_Interceptors
  loading?: LoadingInstance
  showLoading: boolean


  constructor(config:YCX_RequestConfig){
    this.instance = axios.create(config)

    this.interceptors = config.interceptors
    this.showLoading = config.showLoading ?? DEAFULT_LOADING

    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch,
    )

    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch,
    )

    this.instance.interceptors.request.use(
      (config)=>{
        // console.log('所有实例请求成功-请求')
        if (this.showLoading) {
          this.loading = ElLoading.service({
            lock: true,
            text: '正在请求数据....',
            background: 'rgba(0, 0, 0, 0.5)'
          })
        }
        return config
      },
      (err)=>{
        // console.log('所有实例请求失败-请求');
        return err
      }
    )

    this.instance.interceptors.response.use(
      (res)=>{
        // console.log('所有实例响应成功-响应',res);
        // setTimeout(() => {
        this.loading?.close()
        // }, 2000);
        return res
      },
      (err)=>{
        // setTimeout(() => {
          this.loading?.close()
          // }, 2000);

        // console.log('所有实例响应失败-响应');
        console.log(err);
        return err
      }
    )
  }

  // request(config:YCX_RequestConfig):void{
  //   if(config.interceptors?.requestInterceptor){
  //     config = config.interceptors.requestInterceptor(config)
  //   }
  //   this.instance.request(config).then(res=>{
  //     if(config.interceptors?.responseInterceptor){
  //       res = config.interceptors.responseInterceptor(res)
  //     }
  //     console.log(res);
  //     return res
  //   })
  // }

  request<T = any>(config: YCX_RequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 1.单个请求对请求config的处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }

      // 2.判断是否需要显示loading
      if (config.showLoading === false) {
        this.showLoading = config.showLoading
      }
      this.instance
        .request<any, T>(config)
        .then((res) => {
          // 1.单个请求对数据的处理
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 2.将showLoading设置true, 这样不会影响下一个请求
          this.showLoading = DEAFULT_LOADING

          // 3.将结果resolve返回出去
          resolve(res)
        })
        .catch((err) => {
          // 将showLoading设置true, 这样不会影响下一个请求
          this.showLoading = DEAFULT_LOADING
          reject(err)
          return err
        })
    })
  }
  get<T = any>(config: YCX_RequestConfig<T>): Promise<T> {
    // console.log('get请求',config)
    return this.request<T>({ ...config, method: 'GET' })
  }

  post<T = any>(config: YCX_RequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' })
  }

  delete<T = any>(config: YCX_RequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' })
  }

  patch<T = any>(config: YCX_RequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PATCH' })
  }
}

export default YCX_Request
