const polling_task_year = ()=>import('@/views/firesecurity/polling_task_year/index.vue')
export default {
  path: '/system/polling_task_year',
  name: 'polling_task_year',
  component: polling_task_year,
  meta:{
    pid:3,
    id:3,
    index:0,
  },
  children: []
}
