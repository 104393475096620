const ai_report = ()=>import('@/views/firesecurity/ai_report/index.vue')
export default {
  path: '/system/ai_report',
  name: 'ai_report',
  component: ai_report,
  meta:{
    pid:7,
    id:3,
    index:1,
  },
  children: []
}
