const log_info = ()=>import('@/views/firesecurity/log_info/index.vue')
export default {
  path: '/system/log_info',
  name: 'log_info',
  component: log_info,
  meta:{
    pid:15,
    id:1,
    index:1,
  },
  children: []
}
