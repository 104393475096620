const facility_manager = ()=>import('@/views/firesecurity/facility_manager/index.vue')
export default {
  path: '/system/facility_manager',
  name: 'facility_manager',
  component: facility_manager,
  meta:{
    pid:10,
    id:1,
    index:1,
  },
  children: []
}
