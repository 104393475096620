import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { polling_taskyear_state } from './type'
import { getPageList, editPageData } from "@/service/modules/systems";

const polling_taskyearModule: Module < polling_taskyear_state, IRootState >= {
    namespaced: true,
    state() {
        return {
            polling_taskyear_List: [],
            polling_taskyear_Count: 0, //数据条数

        }
    },
    mutations: {
        change_polling_taskyear_List(state, polling_taskyear_List: any[]) {
            state.polling_taskyear_List = polling_taskyear_List
        },
        change_polling_taskyear_Count(state, polling_taskyear_Count: number) {
            state.polling_taskyear_Count = polling_taskyear_Count
        },
    },
    getters: {
        pageListData(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_List`] //选择对应数据
            }
        },
        pageListCount(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_Count`] //选择对应数据条数
            }
        }
    },
    actions: {
        async getPageListAction({ commit }, payload: any) {
            // // 1.获取pageUrl
            // // 1.获取pageUrl
            // const pageName = payload.pageName||''
            const pageUrl = `/custom/get/polling_task`
            // // 2.对页面发送请求
            const pageResult = await getPageList(pageUrl, {...payload.queryInfo,task_type:'年检任务'})
                // console.log(pageResult);
                // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
                // // 3.将数据存储到state中
            const { data, totalRecord } = pageResult.data
            console.log(data);
            commit('change_polling_taskyear_List', data)
            commit('change_polling_taskyear_Count', totalRecord)
        },
        async createPageDataAction({ dispatch }, payload: any) {
            // 1.创建数据的请求
            const { pageName, newData } = payload
            const pageUrl = `/custom/add/polling_task`
            newData.task_type = '年检任务'

            console.log('看看添加的用户', payload)
            await editPageData(pageUrl, newData)
                // await editPageData(pageUrl,payload)

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },

        async editPageDataAction({ dispatch }, payload: any) {
            // 1.编辑数据的请求
            const { pageName, editData, id } = payload
            console.log(editData)
            const pageUrl = `/custom/update/polling_task`
            await editPageData(pageUrl, {...editData, id })

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                pageName,
                queryInfo: {}
            })
        },
        async deletePageDataAction({ dispatch }, payload: any) {
            // 1.获取pageName和id
            // pageName -> /users
            // id -> /users/id
            const { deleteData } = payload
            console.log('我点击了解绑', deleteData)
            const pageUrl = `/custom/del/polling_task`
            let dataInfo = {
                id: deleteData.option
            }
            await editPageData(pageUrl, dataInfo)
                // 2.调用删除网络请求
                // await deletePageData(pageUrl)
                // 3.重新请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },
    }
}

export default polling_taskyearModule
