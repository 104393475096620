const performance_assessment = ()=>import('@/views/firesecurity/performance_assessment/index.vue')
export default {
  path: '/system/performance_assessment',
  name: 'performance_assessment',
  component: performance_assessment,
  meta:{
    pid:7,
    id:2,
    index:1,
  },
  children: []
}
