import { Module } from "vuex";
import { IRootState } from '@/store/types'
import { risk_organization_state } from './type'
import { getPageList, editPageData } from "@/service/modules/systems";

const risk_organizationModule: Module < risk_organization_state, IRootState >= {
    namespaced: true,
    state() {
        return {
            risk_organization_List: [],
            risk_performance_List:[],
            risk_organization_Count: 0, //数据条数

        }
    },
    mutations: {
      change_risk_performance_List(state, risk_performance_List: any[]) {
        state.risk_performance_List = risk_performance_List
    },
        change_risk_organization_List(state, risk_organization_List: any[]) {
            state.risk_organization_List = risk_organization_List
        },
        change_risk_organization_Count(state, risk_organization_Count: number) {
            state.risk_organization_Count = risk_organization_Count
        },
    },
    getters: {
        pageListData(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_List`] //选择对应数据
            }
        },
        pageListCount(state) {
            return (pageName: string) => {
                return (state as any)[`${pageName}_Count`] //选择对应数据条数
            }
        }
    },
    actions: {
        async getPageListAction({ commit }, payload: any) {
            // // 1.获取pageUrl
            // // 1.获取pageUrl
            // const pageName = payload.pageName||''
            const pageUrl = `/custom/get/risk_organization`
            // // 2.对页面发送请求
            let create_time = null
            if(payload.queryInfo)
            {
              if(payload.queryInfo.create_time)
              {
                create_time = JSON.stringify(payload.queryInfo.create_time)
              }
            }
            const pageResult = await getPageList(pageUrl,{...payload.queryInfo,create_time})
                // console.log(pageResult);
                // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
                // // 3.将数据存储到state中
            const { data, totalRecord } = pageResult.data
            console.log(data);
            commit('change_risk_organization_List', data)
            commit('change_risk_organization_Count', totalRecord)
        },
        async createPageDataAction({ dispatch }, payload: any) {
            // 1.创建数据的请求
            const { pageName, newData } = payload
            const pageUrl = `/custom/add/risk_organization`

            console.log('看看添加的用户', payload)
            await editPageData(pageUrl, newData)
                // await editPageData(pageUrl,payload)

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },

        async editPageDataAction({ dispatch }, payload: any) {
            // 1.编辑数据的请求
            const { pageName, editData, id } = payload
            console.log(editData)
            const pageUrl = `/custom/update/risk_organization`
            await editPageData(pageUrl, {...editData, id })

            // 2.请求最新的数据
            dispatch('getPageListAction', {
                pageName,
                queryInfo: {}
            })
        },
        async riskPerformanceInfo_getInfo({commit}){
          const pageUrl = `/custom/get/evaluation`
          // // 2.对页面发送请求
          const pageResult = await getPageList(pageUrl,{})
              // console.log(pageResult);
              // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
              // // 3.将数据存储到state中
          const { data, totalRecord } = pageResult.data
          console.log(data,'看看请求的数据是什么');
          commit('change_risk_performance_List', data)
        },
        async riskPerformanceInfo({commit},payload:any){
          console.log(payload,'看看')
          const pageUrl = `/custom/update/evaluation`
          await editPageData(pageUrl, {...payload })

        },
        async deletePageDataAction({ dispatch }, payload: any) {
            // 1.获取pageName和id
            // pageName -> /users
            // id -> /users/id
            const { deleteData } = payload
            console.log('我点击了解绑', deleteData)
            const pageUrl = `/custom/del/risk_organization`
            let dataInfo = {
                id: deleteData.option
            }
            await editPageData(pageUrl, dataInfo)
                // 2.调用删除网络请求
                // await deletePageData(pageUrl)
                // 3.重新请求最新的数据
            dispatch('getPageListAction', {
                queryInfo: {}
            })
        },
    }
}

export default risk_organizationModule
