import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { IuserState } from './types'
import { getPageList, editPageData } from '@/service/modules/systems'

const userModule: Module<IuserState, IRootState> = {
  namespaced: true,
  state() {
    return {
      userList: [
        // {
        //   name: '超级管理员',
        //   phone: '13380246065',
        //   type: '超级管理员',
        //   createTime: '2023-6-21'
        // }
      ],
      myorganization:[],
      myInfo: {}, //'我的个人信息'
      userCount: 0, //数据条数
      chair_num:0,//占用座椅超时的数量
      chair_nums:0,//占用座椅超时的数量
      report_num:0,//报警的数量
      controller_num:0,//继电器控制的数量
      roleList: [], //对应数据
      roleCount: 0, //数据条数
      goodsList: [], //对应数据
      goodsCount: 0, //数据条数
      menuList: [], //对应数据
      menuCount: 0, //数据条数
      chair_info: {}, //智慧座椅
      facility_info: [], //告警设备
      report_info: [
        {
          facility_company: ''
        }
      ], //告警消息
      rondalog_log: [
        {
          rondalog_starttime: ''
        }
      ], //巡逻任务
      rondatask_info: [], //巡逻人员
      show_info: [], //智慧显示继电器
      first_menus: [
        {
          name: '大数据页面',
          url: '/analysis/datedetail',
          id: 1,
          children: [
            {
              name: '大数据页面',
              url: '/analysis/datedetail',
              value:"analysis"
            }
          ]
        },
        {
          name: '规范执法',
          id: 2,
          url: '/system/dateInfo_chair',
          children: [
            {
              name: '智慧座椅',
              url: '/system/dateInfo_chair',
              value:"dateInfo_chair"
            },
            {
              name: '占用记录',
              url: '/system/dataInfoAll_errotips',
              value:"dataInfoAll_errotips"
            },
            {
              name: '智慧显示',
              url: '/system/dateInfo_showinfo',
              value:"dateInfo_showinfo"
            },
            {
              name: '管控记录',
              url: '/system/dataInfoAll_controlllog',
              value:"dataInfoAll_controlllog"
            }
          ]
        },
        {
          name: '数字巡查',
          id: 3,
          url: '/system/dateInfo_checkfaility',
          children: [
            {
              name: '巡查设备',
              url: '/system/dateInfo_checkfaility',
              value:"dateInfo_checkfaility"
            },
            {
              name: '人员计划',
              url: '/system/schedule_userplan',
              value:"schedule_userplan"
            },
            {
              name: '巡查记录',
              url: '/system/patrol_rondalog',
              value:"patrol_rondalog"
            }
          ]
        },
        {
          name: '智慧门禁',
          url: '/system/dateInfo_faility',
          id: 4,
          children: [
            {
              name: '设备管理',
              url: '/system/dateInfo_faility',
              value:"dateInfo_faility"
            },
            {
              name: '报警信息',
              url: '/system/dateInfo_report',
              value:"dateInfo_report"
            }
          ]
        },
        // {
        //   name:"位置信息",
        //   id:5,
        //   url:"/system/dataInfo_location",
        //   children:[
        //     {
        //       name:"位置信息",
        //       url:"/system/dataInfo_location",
        //     }
        //   ]
        // },
        {
          name: '系统信息',
          id: 6,
          url: '/system/setting',
          children: [
            {
              name: '系统设置',
              url: '/system/setting',
              value:"setting"
            }
          ]
        }
      ] //一级导航栏
    }
  },
  mutations: {
    updateOrganizationInfo(state,payload:any){
      state.myorganization = payload
    },
    changMySelfInfo(state, info: any) {
      state.myInfo = info
      let jurisdiction = JSON.parse(info.jurisdiction)
      let menu = state.first_menus
      console.log(jurisdiction,'看看职权',menu)
      if(!jurisdiction.dateInfo_chair_header)
      {
        menu.splice(1,1)
        console.log('执法规范没有')
      }
      if(!jurisdiction.analysis)
      {
        menu.splice(0,1)
        console.log('大数据没有')
      }
      if(!jurisdiction.dateInfo_checkfaility_header)
      {
        menu.splice(2,1)
        console.log('智慧门禁没有')
      }
      if(!jurisdiction.setting)
      {
        menu.splice(3,1)
        console.log('系统设置没有没有')
      }
      menu.forEach((v:any) => {
          v.children.forEach((n:any,i:any)=>{
            if(!jurisdiction[n.value])
            {
              console.log(jurisdiction[n.value],n.value)
              v.children.splice(i,1)
            }
          })
      });
      state.first_menus = menu
      // console.log('查询一下个人的数据', state.myInfo)
    },
    changeHomeList(state, info: any) {
      // console.log(info, '看看首页的数据')
      if (info.code !== 200) return
      state.chair_info = info.chair_info
      state.facility_info = info.facility_info
      state.report_info = info.report_info
      state.rondalog_log = info.rondalog_log
      state.rondatask_info = info.rondatask_info
      state.show_info = info.show_info
      state.myInfo = info.userInfo
      state.chair_num = info.chair_num
      state.chair_nums = info.chair_nums
      state.report_num = info.report_num
      state.controller_num = info.controller_num
      // console.log(state.show_info, 'cssfc')
    },
    changeUsersList(state, userList: any[]) {
      state.userList = userList
    },
    changeUsersCount(state, userCount: number) {
      state.userCount = userCount
    },
    changeRoleList(state, list: any[]) {
      state.roleList = list
    },
    changeRoleCount(state, count: number) {
      state.roleCount = count
    },
    changeGoodsList(state, list: any[]) {
      state.goodsList = list
    },
    changeGoodsCount(state, count: number) {
      state.goodsCount = count
    },
    changeMenuList(state, list: any[]) {
      state.menuList = list
    },
    changeMenuCount(state, count: number) {
      state.menuCount = count
    }
  },
  getters: {
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}List`] //选择对应数据
      }
    },
    pageHomeData(state) {
      return (state as any)[`rondatask_info`] //选择对应数据
    },
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}Count`] //选择对应数据条数
      }
    }
  },
  actions: {
    async getPageListAction({ commit }, payload: any) {
      // // 1.获取pageUrl
      const pageName = payload.pageName
      const pageUrl = `/custom/checkuserinfo`

      // // 2.对页面发送请求
      const pageResult = await getPageList(pageUrl, payload.queryInfo)
      // const pageResult = {data:{list:'',totalCount:0}}//模拟数据结构
      // console.log(pageResult,'看看数据');
      const { data, totalRecord } = pageResult.data
      console.log(data,'看看总用户数量',totalRecord);
      commit('changeUsersList', data)
      commit('changeUsersCount', totalRecord)
      // // 3.将数据存储到state中
      // const { list, totalCount } = pageResult.data

      // const changePageName =
      //   pageName.slice(0, 1).toUpperCase() + pageName.slice(1)
      // commit(`change${changePageName}List`, list)
      // commit(`change${changePageName}Count`, totalCount)
    },
    async getMySelfInfoAction({ commit }, payload: any) {
      // /admin/checkuserinfo
      const pageUrl = `/custom/getmyself`
      const pageResult = await getPageList(pageUrl, payload)
      let data = pageResult.data[0]
      // console.log('查询一下个人的数据',data)
      commit('changMySelfInfo', data)
    },
    async createPageDataAction({ dispatch }, payload: any) {
      const pageName = payload.pageName
      const pageUrl = `/custom/register/company`
      let type = 0
      if(payload.newData.role=='超级管理员')
      {
        type = 2
      }
      if(payload.newData.role=='民政人员')
      {
        type = 2
      }
      if(payload.newData.role=='公办机构')
      {
        type = 1
      }
      if(payload.newData.role=='直属机构')
      {
        type = 1
      }
      if(payload.newData.role=='社会机构')
      {
        type = 1
      }
      if(payload.newData.role=='殡葬机构')
      {
        type = 1
      }
      if(payload.newData.role=='养老服务中心')
      {
        type = 1
      }

      const pageResult = await editPageData(pageUrl, {...payload.newData,type})
      console.log(payload, '看看添加的机构信息内容')

      dispatch('getPageListAction', {
        queryInfo: {}
      })
    },
    async homePageDataAction({ commit }, payload: any) {
      // /admin/gethomeinfo
      const pageUrl = `/admin/gethomeinfo`
      const pageResult = await getPageList(pageUrl, payload)

      console.log(pageResult,'首页的数据')
      commit('changeHomeList', pageResult)
    },
    //获取个人的组织信息 超级管理员为全部的
    async handleGetMyOraganization({ commit }, payload: any){
      const pageUrl = `/custom/get/companylist`

      const pageResult = await getPageList(pageUrl, payload)
      let {data} = pageResult.data
      console.log(data,'看看获取的组织信息')
     commit('updateOrganizationInfo',data)
    },
    async editPageDataAction({ dispatch }, payload: any) {
      const pageName = payload.pageName
      const pageUrl = `/custom/edituserinfo`
      let type = 0
      if(payload.editData.role=='超级管理员')
      {
        type = 2
      }
      if(payload.editData.role=='民政人员')
      {
        type = 2
      }
      if(payload.editData.role=='公办机构')
      {
        type = 1
      }
      if(payload.editData.role=='直属机构')
      {
        type = 1
      }
      if(payload.editData.role=='社会机构')
      {
        type = 1
      }
      if(payload.editData.role=='养老服务中心')
      {
        type = 1
      }
      if(payload.editData.role=='殡葬机构')
      {
        type = 1
      }
      const pageResult = await editPageData(pageUrl,{...payload.editData,type})

      // console.log(payload,'看看修改的内容')
      dispatch('getPageListAction', {
        queryInfo: {}
      })
    },
    async deletePageDataAction({ dispatch }, payload: any) {
      // 1.获取pageName和id
      // pageName -> /users
      // id -> /users/id
      const { pageName, id } = payload
      const pageUrl = `/custom/delinfo/userinfo`

      // 2.调用删除网络请求
      await editPageData(pageUrl,{...payload})

      // 3.重新请求最新的数据
      dispatch('getPageListAction', {
        queryInfo: { }
      })
    }
  }
}

export default userModule
